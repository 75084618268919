<style lang="less">
@import './index.less';
</style>
<template>
  <div class="container" @keydown.enter="handleSubmit">
    <div class="header">
<!--      <SelectLang class="select-lang-trigger" />-->
    </div>
    <div class="main">
      <div class="main-left">
        <div class="main-left-item">
          <!-- <img class="main-left-logo" src="../../assets/images/logo.png">    -->
        </div>
        <div style="height:120px">

        </div>
        <div class="main-left-item"> 
          <h1 class="main-left-title" style="color:rgb(54,93,201)">欢迎登录承运商系统</h1>
        </div>
      </div>
      <div class="main-right">
        <div class="main-right-top">
          <div class="main-right-top-logo">
            <img src="../../assets/images/welcome.png">
          </div>
          <div class="main-right-top-desc">{{$t('user.login.title')}}</div>
        </div>
        <div class="main-right-container">
          <div class="login">
            <a-form ref="loginForm" :model="form" layout="vertical">
              <a-alert v-if="isLoginError" type="error" showIcon style="margin-bottom: 24px;" :message="$t('user.login.message-invalid-credentials')" />
              <div class="login-username">
                <a-form-item name="username" :rules="[{ required: true, message: $t('user.userName.required'), trigger: 'blur' }]">
                  <a-input v-model:value="form.username" :placeholder="$t('user.login.username.placeholder')" size="large">
                    <template #prefix>
                      <UserOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                </a-form-item>
              </div>
              <div class="login-password">
                <a-form-item name="password" :rules="[{ required: true, message: $t('user.password.required'), trigger: 'blur' }]">
                  <a-input v-model:value="form.password" maxlength="20" type="password" :placeholder="$t('user.login.password.placeholder')" size="large">
                    <template #prefix>
                      <LockOutlined style="color: rgba(0, 0, 0, 0.25)" />
                    </template>
                  </a-input>
                </a-form-item>
              </div>
              <div class="login-submit">
                <a-form-item>
                  <a-button type="primary" @click="handleSubmit" size="large" :loading="state.loginBtn" :disabled="state.loginBtn" block>
                    {{$t('user.login.login')}}
                  </a-button>
                </a-form-item>
              </div>
            </a-form>
          </div>
        </div>
      </div>
    </div>
    <GlobalFooter class="footer" />
  </div>
</template>

<script>
import { reactive, ref, toRefs } from 'vue'
// import SelectLang from '@/components/SelectLang'
import GlobalFooter from '@/components/GlobalFooter'
import { UserOutlined, LockOutlined } from '@ant-design/icons-vue'
import { useStore } from 'vuex'
import { timeFix } from '@/utils/util'
import md5 from 'md5'
import { notification } from 'ant-design-vue'
import { useRouter } from 'vue-router'
import storage from 'store'
export default ({
  setup () {
    const loginForm = ref()
    const router = useRouter()
    const store = useStore()
    const data = reactive({
      form: {
        username: '',
        password: '123456'
      },
      state: {
        loginBtn: false
      },
      isLoginError: false
    })

    const handleSubmit = () => {
      window.localStorage.removeItem('tabs')
      loginForm.value.validate().then(val => {
        data.state.loginBtn = true
        const loginParams = { ...val }
        loginParams.password = md5(val.password)
        loginParams.loginType = 1
        loginParams.platformNo = 100000007
        // 100000007
        loginParams.clientId = 'huayue'
        loginParams.clientSecret = 'huayue'
        store.dispatch('Login', loginParams)
          .then(res => loginSuccess(res))
          .catch(err => requestFailed(err))
          .finally(() => { data.state.loginBtn = false })
      })
    }

    const loginSuccess = (res) => {
      router.push({ path: '/' })
      if (storage.get('accessToken') !== undefined) {
        // 延迟 1 秒显示欢迎信息
        setTimeout(() => {
          notification.success({
            message: '欢迎',
            description: `${timeFix()}，欢迎回来`
          })
        }, 500)
      }
      data.isLoginError = false
    }

    const requestFailed = (err) => {
      console.log(err)
      data.isLoginError = true
      notification.error({
        message: '错误',
        description: ((err.response || {}).data || {}).msg || '请求出现错误，请稍后再试',
        duration: 4
      })
    }

    return {
      loginForm,
      ...toRefs(data),
      handleSubmit
    }
  },
  components: {
    // SelectLang,
    GlobalFooter,
    UserOutlined,
    LockOutlined
  }
})
</script>
