<template>
  <footer class="global-footer copyright">
    <div class="global-footer-links">
        <a href=""></a>
        <a href=""></a>
        <a href=""></a>
    </div>
    <div class="global-footer-copyright">
      HuaYue System ©2024 Created by HuaYue IT
    </div>
  </footer>
</template>

<script>
export default {
  name: 'GlobalFooter',
  data () {
    return {

    }
  },
  computed: {

  },
  methods: {

  }
}
</script>

<style lang="less" scoped>
    .global-footer {
        margin: 48px 0 24px 0;
        padding: 0 16px;
        text-align: center;
        &-links {
            margin-bottom: 8px;

            a {
                font-size: 14px;
                color: #808695;
                -webkit-transition: all .2s ease-in-out;
                transition: all .2s ease-in-out;
                &:not(:last-child) {
                        margin-right: 40px;
                }
                &:hover {
                    color: #515a6e;
                }
            }
        }
        &-copyright {
            color: #808695;
            font-size: 14px;
        }
    }
    .copyright {
        flex: 0 0 auto;
    }

</style>
