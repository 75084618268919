// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/login-bg.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../../assets/images/login-left.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".container {\n  width: 100%;\n  height: 100vh;\n  padding: 110px 0 144px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: 50%;\n}\n.container .header {\n  position: absolute;\n  top: 0;\n  width: 100%;\n  height: 40px;\n  line-height: 44px;\n  text-align: right;\n}\n.container .header .select-lang-trigger {\n  cursor: pointer;\n  padding: 12px;\n  margin-right: 24px;\n  display: inline-flex;\n  align-items: center;\n  justify-content: center;\n  font-size: 18px;\n  vertical-align: middle;\n  color: #bfbfbf;\n}\n.container .main {\n  display: flex;\n  height: 100%;\n  width: 60%;\n  min-width: 960px;\n  margin: auto;\n  display: table;\n}\n.container .main-left {\n  width: 40%;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  background-repeat: no-repeat;\n  background-position: 50%;\n  background-size: 100% 100%;\n  display: table-cell;\n  vertical-align: middle;\n}\n.container .main-left-item {\n  margin: 24px 0;\n  text-align: center;\n}\n.container .main-left-logo {\n  width: 203px;\n  height: 68px;\n}\n.container .main-left-title {\n  color: floralwhite;\n  font-size: 34px;\n}\n.container .main-right {\n  width: 60%;\n  background-color: #ffffff;\n  display: table-cell;\n  vertical-align: middle;\n}\n.container .main-right-top {\n  padding: 8px 0;\n  text-align: center;\n}\n.container .main-right-top-logo img {\n  margin: 24px auto;\n}\n.container .main-right-top-desc {\n  font-size: 14px;\n  color: #808695;\n}\n.container .main-right-container {\n  flex: 1;\n  padding: 32px 0;\n  width: 384px;\n  margin: 0 auto;\n}\n.container .footer {\n  position: absolute;\n  bottom: 0;\n  width: 100%;\n  margin: 48px 0 24px;\n  padding: 0 16px;\n  text-align: center;\n}\n", ""]);
// Exports
module.exports = exports;
